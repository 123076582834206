.markdown {
  pre, p, ul, ol, blockquote {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    padding-left: 20px;
  }
  li {
    padding-left: 4px;
  }
}

.character-card {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);


  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }

  h3 {
    margin-top: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin-top: 0;
  }
}
.character-card.dark-mode {
  background-color: #424242 !important;
}
