.markdown pre:first-child, .markdown p:first-child, .markdown ul:first-child, .markdown ol:first-child, .markdown blockquote:first-child {
  margin-top: 0;
}

.markdown pre:last-child, .markdown p:last-child, .markdown ul:last-child, .markdown ol:last-child, .markdown blockquote:last-child {
  margin-bottom: 0;
}

.markdown ul {
  padding-left: 20px;
}

.markdown li {
  padding-left: 4px;
}

.character-card {
  border-radius: 10px;
  transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 8px #0000001a;
}

.character-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 16px #0003;
}

.character-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.character-card p {
  margin-top: 0;
  font-size: 18px;
}

.character-card.dark-mode {
  background-color: #424242 !important;
}

/*# sourceMappingURL=index.9200ec66.css.map */
